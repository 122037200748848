import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';
import Router, { route } from 'preact-router';
import Match from 'preact-router/match';
import Context from "context";
import Header from './organisms/header';

// Code-splitting is automated for `routes` directory
// import Home from '../routes/home';
// import Profile from '../routes/profile';
import Onboard from '../routes/Onboard';
import Login from '../routes/Login';
import Main from '../routes/Main';
import Backoffice from '../routes/Backoffice';
import Error from '../routes/Error';
import moment from 'moment';

import { request, apiGetUser, apiGetUserMy, apiGetUserLogin } from '@/../api.js';

const App = () => {
	// const [token, setToken] = useState()
	const [isBigBoss, setIsBigBoss] = useState(false)
	const [isDirector, setIsDirector] = useState(false)
	//
	const [overviewFilter, setOverviewFilter] = useState({
		week_number_start:moment(new Date()).isoWeek(),
		week_number_end: moment(new Date()).isoWeek(),
		year: moment().format("YYYY"),
	  })
	const [detailFilter, setDetailFilter] = useState({})
	const [statisticFilter, setStatisticFilter] = useState({})
	const [userFilter, setUserFilter] = useState({})

	useEffect(() => {

		if(window.location.hash.split('/')[1] == 'login' && window.location.hash.split('/')[2]){
			apiGetUserLogin(window.location.hash.split('/')[2])
			.then(async (res) => {
				// console.log(res.data)
				request.defaults.headers.common['authorization'] = res.data['access-token'];
				localStorage.setItem('token', res.data['access-token']);
				await getUserData()
				if(res.data.new)
					route('/onboard')
				else
					route('/')
			})
			.catch(e => {
				route('/error')
			})
		}else{
			getUserData()
		}
	}, [route])

	const getUserData = async () => {
		const token = localStorage.getItem("token");
		// console.log('token', token)
		if(token){
			request.defaults.headers.common['authorization'] = token
			await apiGetUserMy().then(res => {
				Object.entries(res.data[0]).map(([key, val]) => {
					localStorage.setItem(key, val)
				})
				setIsBigBoss(res.data[0].is_bigboss)
				setIsDirector(res.data[0].is_director)
			}).catch(e => {
				setIsBigBoss(false)
			        setIsDirector(false)
				route('/login')
			})
		}else
			route('/login')
	}

	const context = {
		isBigBoss, setIsBigBoss,
		isDirector, setIsDirector,
		overviewFilter, setOverviewFilter,
		detailFilter, setDetailFilter,
		statisticFilter, setStatisticFilter,
		userFilter, setUserFilter
	}

	return(
		<div id="app">
			<Context.Provider value={context}>
				<Match>
					{({ url }) => {
						if(url != '/login' && url != '/onboard')
							return <Header isDirector isBigBoss />
					}}
				</Match>
				<Router>
					{/* <Home path="/" />
					<Profile path="/profile/" user="me" />
					<Profile path="/profile/:user" /> */}
					<Onboard path="/onboard" />
					<Login path="/login" />
					<Main path="/" />
					<Backoffice path="/backoffice/:subpage"  />
					<Error path="/error" />
				</Router>
			</Context.Provider>
		</div>
	)
}

export default App;
