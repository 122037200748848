import axios from "axios";

export const baseURL =
   process.env.PREACT_APP_BASE_API ||
   `${window.location.protocol}//api.${window.location.hostname}`;


export const request = axios.create({
    baseURL,
});

export const apiGetUser = (data) => request.get('/user' , { params: data })
export const apiGetUserMy = () => request.get('/user/my')
export const apiPostUser = (data) => request.post('/user/requestLogin', data);
export const apiPutUser = (id, data) => request.put('/user/update/'+id, data);
export const apiPutUserOnboard = (data) => request.put('/user/update', data);
// export const apiGetUserLoginVerity = (data) => request.get('/user/login/vertify/'+data)
export const apiGetUserLogin = (token) => request.get('/user/login/'+ token);
export const apiDeleteUser = (id) => request.delete('/user/'+id )

export const apiGetWeek = (data) => request.get('/week', { params: data });
export const apiGetMyWeek = (data) => request.get('/week/my', { params: data });
export const apiGetMyDepartmentWeek = () => request.get('/week/my/department');
export const apiPostWeek = (data) => request.post('/week', data);

export const apiGetColors = (id, data) => request.get('/color' + (id ? '/' + id : ''), { params: data });
export const apiPostColors = (data) => request.post('/color', data);
export const apiPutColors = (id, data) => request.put('/color/'+id, data);
export const apiDeleteColors = (id) => request.delete('/color/'+id);

export const apiGetDepartment = (id) => request.get('/department' + (id ? '/' + id : ''));
export const apiGetDepartmentEmployee = (id) => request.get(`/department/${id}/employees`);
export const apiPostDepartment = (data) => request.post('/department', data);
export const apiPutDepartment = (id, data) => request.put(`/department/${id}`, data);
export const apiDeleteDepartment = (id) => request.delete(`/department/${id}`);

export const apiGetFonction = (id) => request.get('/fonction' + (id ? '/' + id : ''));
export const apiPostFonction = (data) => request.post('/fonction', data);
export const apiPutFonction = (id, data) => request.put('/fonction/'+id, data);
export const apiDeleteFonction = (id) => request.delete('/fonction/' + id);
